<template>
	<b-container>
		<div class="pb-5" v-on:keyup.enter="login">
			<b-row>
				<b-col>
					<h1 class="d-flex justify-content-center mb-5">3DONS BANK</h1>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-input-group size="sm" class="mb-2">
						<b-input-group-prepend is-text>
							<b-icon icon="person-fill"></b-icon>
						</b-input-group-prepend>
						<b-form-input
							autofocus
							v-model="user.account"
							type="text"
						></b-form-input>
					</b-input-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-input-group size="sm" class="mb-2">
						<b-input-group-prepend is-text>
							<b-icon icon="key-fill"></b-icon>
						</b-input-group-prepend>
						<b-form-input
							v-model="user.password"
							type="password"
						></b-form-input>
					</b-input-group>
				</b-col>
			</b-row>
			<div v-if="errors" class="d-flex justify-content-center mb-2 text-danger">
				<span v-for="(error, k) in errors" :key="k" variant="danger"
					><small>{{ error[0] }}</small></span
				>
			</div>
			<b-button style="width: 100%" @click.prevent="login">LOGIN</b-button>
		</div>
	</b-container>
</template>
<script>
// import { LOGIN } from "@/store/actions.type"
import { mapState } from "vuex"
import ApiService from "../common/api.service"
import { consoleLog, errorLog, modalErr } from "../common/utils"

export default {
	created() {
		this.getUser()
	},
	computed: {
		...mapState({
			errors: (state) => state.auth.errors,
		}),
	},
	data() {
		return {
			user: {
				account: "",
				password: "",
			},
		}
	},
	methods: {
		getUser() {
			const account = window.localStorage.getItem("account")
			const password = window.localStorage.getItem("password")
			if (account !== null && password !== null) {
				this.user.account = account
				this.user.password = password
			}
		},
		login() {
			consoleLog("login button click")
			const validate = this.validate(this.user)
			if (validate) {
				ApiService.post("login", this.user)
					.then(({ data }) => {
						consoleLog(data)
						if (data.result_code == 0) {
							consoleLog("login data : ", data)
							this.$store.commit("setUser", data)
							window.localStorage.setItem("account", this.user.account)
							window.localStorage.setItem("password", this.user.password)
							this.showMsgBoxOk("로그인에 성공하였습니다.")
						} else {
							this.$store.commit("logout")
							switch (data.result_code) {
								case 1000:
									this.showMsgBoxWrong("아이디가 존재하지 않습니다.")
									break
								case 1001:
									this.showMsgBoxWrong("아이디를 입력해주세요.")
									break
								case 1002:
									this.showMsgBoxWrong("비밀번호가 일치하지 않습니다.")
									break
								case 1003:
									this.showMsgBoxWrong("비밀번호를 입력해주세요.")
									break
							}
						}
					})
					.catch((response) => {
						errorLog(response)
						this.$router.go()
					})
			}
		},
		validate(user) {
			if (user.account == null || user.account == "") {
				this.showMsgBoxWrong("아이디를 입력해주세요.")
				return false
			} else if (user.password == null || user.password == "") {
				this.showMsgBoxWrong("비밀번호를 입력해주세요.")
				return false
			}
			return true
		},
		showMsgBoxOk(msg) {
			this.$bvModal
				.msgBoxOk(msg)
				.then((value) => {
					this.boxOne = value
					this.$router.push({ name: "reports" })
				})
				.catch((err) => {
					modalErr(err)
				})
		},
		showMsgBoxWrong(msg) {
			this.$bvModal
				.msgBoxOk(msg)
				.then((value) => {
					this.boxOne = value
				})
				.catch((err) => {
					modalErr(err)
				})
		},
	},
}
</script>
<style scoped>
.container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
